import React from 'react'
import Women from './Women'

function WomenDashboard() {
  return (
    <>
      <Women/> 
    </>
  )
}

export default WomenDashboard
